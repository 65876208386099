<template>
  <v-card outlined>
    <v-card-title>Datos personales</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field v-model="formData.code" label="Código interno (IES)" dense />
        </v-col>

        <v-col cols="12">
          <v-prd-date-picker-field
            dense
            v-model="formData.last_rgpd_acceptance"
            label="Última aceptación RGPD"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            label="Nombre"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="formData.surname"
            label="Apellidos"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="formData.tin"
            label="NIF/NIE/Pasaporte"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="formData.gender"
            :items="genders"
            label="Género"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-prd-date-picker-field
            dense
            v-model="formData.birth_date"
            :rules="[$data.$globalRules.required]"
            label="Fecha de nacimiento"
            is-birth-date
            required
          />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="formData.academic_title_id"
            :items="academicTitles"
            :loading="loadingAcademicTitles"
            item-value="const"
            item-text="title"
            label="Título académico"
            dense
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import genders from "@/utils/helpers";

export default {
  components: {
    vPrdDatePickerField: () => import("@/components/v-prd/DatePickerTextField"),
  },
  props: {
    formData: { type: Object, required: true },
  },
  data: () => ({
    loadingAcademicTitles: null,
    academicTitles: [],
    genders,
  }),
  async mounted() {
    await this.getAcademicTitles();
  },
  methods: {
    async getAcademicTitles() {
      try {
        this.loadingAcademicTitles = true;
        const response = await this.$store.state.currentService.getAcademicTitles();
        this.academicTitles = response.data;
      } catch (e) {
        this.$notifyError(
          undefined,
          "Error al descargar los títulos académicos disponibles | " + e
        );
      } finally {
        this.loadingAcademicTitles = false;
      }
    },
  },
};
</script>

<style scoped></style>
